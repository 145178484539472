var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "portfolio-new-form"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "row push"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_vm.$auth.isAdmin ? _c('FormGroup', {
    attrs: {
      "id": "companyId",
      "label": "Company (Admin Only)",
      "type": "select",
      "placeholder": "N/A",
      "options": _vm.companies.map(function (company) {
        return {
          label: company.name,
          value: company._id
        };
      })
    },
    on: {
      "input": _vm.onChangeCompany
    },
    model: {
      value: _vm.form.companyId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyId", $$v);
      },
      expression: "form.companyId"
    }
  }) : _vm._e(), _vm.$permissions.isCompanyUser() ? _c('FormGroup', {
    attrs: {
      "id": "type",
      "label": "Type",
      "type": "select",
      "placeholder": "N/A",
      "options": !_vm.$permissions.isCompanyUser() ? [{
        label: 'Individual Assets',
        value: 'custom'
      }] : [{
        label: 'Entity',
        value: 'entity'
      }, {
        label: 'Company',
        value: 'company'
      }, {
        label: 'Individual Assets',
        value: 'custom'
      }]
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "name",
      "disabled": _vm.disabled,
      "error": _vm.error['name'],
      "label": "Name",
      "type": "text"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm.form.type === 'entity' ? _c('FormGroup', {
    attrs: {
      "id": "entityIds",
      "label": "Entity",
      "type": "select-array-list",
      "placeholder": "N/A",
      "options": _vm.entities.map(function (entity) {
        return {
          label: entity.legalName,
          value: entity._id
        };
      }),
      "overflow": "",
      "search": true,
      "error": _vm.error.entityIds
    },
    on: {
      "input": function input() {
        return _vm.onChangeName(_vm.selectedEntity ? _vm.selectedEntity.legalName : null);
      }
    },
    model: {
      value: _vm.form.entityIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "entityIds", $$v);
      },
      expression: "form.entityIds"
    }
  }) : _vm._e(), _vm.selectedCompany && _vm.form.type === 'company' ? _c('p', [_vm._v(" This portfolio will contain all assets that are under the company "), _c('strong', [_vm._v(_vm._s(_vm.selectedCompany.name))]), _vm._v(". Only Company users can view this portfolio. ")]) : _vm._e(), _vm.form.type === 'entity' ? _c('p', [_vm._v(" This portfolio will contain all assets that are under the selected entities. Any entity users associated with these entites will be able to see this portfolio, as well as all company users. ")]) : _vm._e(), _vm.form.type === 'custom' ? _c('div', [_c('p', [_vm._v("By default, this portfolio will only be visible to you.")]), _c('p', [_vm._v("If you would like other users to see this portfolio, add them below.")])]) : _vm._e(), _vm.form.type === 'custom' ? _c('FormGroup', {
    attrs: {
      "id": "userSubs",
      "label": "Users",
      "type": "select-array-list",
      "placeholder": "N/A",
      "options": _vm.users.filter(function (user) {
        return user.userSub !== _vm.$auth.user.sub;
      }).map(function (user) {
        return {
          label: "".concat(user.name, " ").concat(user.entity ? "(".concat(user.entity.legalName, ")") : ''),
          value: user.userSub
        };
      })
    },
    model: {
      value: _vm.form.userSubs,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userSubs", $$v);
      },
      expression: "form.userSubs"
    }
  }) : _vm._e()], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v(" Portfolio Information")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);
}]

export { render, staticRenderFns }