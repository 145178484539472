<template>
  <div>
    <PageHeader title="Create Asset Group" />

    <div class="content content-full content-boxed">
      <div v-if="errorAction.create" class="alert alert-danger">{{ errorAction.create.message }}</div>
      <div class="block block-rounded">
        <div class="block-content">
          <PortfolioForm v-model="form" :error="validationErrors" />
          <div class="text-right pb-4">
            <button data-cy="submit" type="submit" class="btn btn-primary" :disabled="loadingAction.create" @click="onCreate">
              <span v-if="loadingAction.create"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
              <span v-else><i class="fa fa-check-circle mr-1"></i> Create</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PageHeader from '@/components/PageHeader';
import PortfolioForm from '@/components/forms/PortfolioForm';

export default {
  name: 'PortfolioCreate',
  components: {
    PageHeader,
    PortfolioForm
  },
  data() {
    return {
      form: {
        name: '',
        type: 'custom',
        userSubs: []
      }
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'assetGroup/loadingAction',
      errorAction: 'assetGroup/errorAction',
      companies: 'company/companies',
      validationErrors: 'assetGroup/validationErrors'
    })
  },
  async mounted() {
    this.reset();

    if (this.$auth.isAdmin) {
      await this.listCompanies({ data: { params: { $select: '_id,name' } } });
      this.form.companyId = this.companies[0]._id;
    } else {
      this.form.companyId = this.$auth.companyId;
    }
  },
  methods: {
    ...mapActions({
      create: 'assetGroup/create',
      list: 'assetGroup/list',
      listCompanies: 'company/list'
    }),
    ...mapMutations({
      reset: 'assetGroup/CLEAR_ASSETGROUP'
    }),
    async onCreate() {
      await this.create({ data: this.form });

      if (!this.errorAction.create) {
        await this.list();
        this.$router.push({ name: 'portfolio-management' });
        this.$toasted.success('Portfolio created successfully!', { position: 'bottom-right', duration: 3000 });
      }
    }
  }
};
</script>
